import React, { useState, useRef } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import KenIcon from '../../Assests/Group.png';
import Video3 from '../../Assests/vide03.png'
// import KennovateIcon from '../../Assests/kennovate-icon.png';
import KernelIcon from '../../Assests/kernel-icon.png';
import quotation1 from '../../Assests/quotation1.png';
import quotation2 from '../../Assests/quotation2.png';
import diamond from '../../Assests/diamond.png';
import carrersbg from '../../Assests/careers-background.png'
import makeStyles from '@mui/styles/makeStyles';
import corevalue from '../../Assests/Corevalues-bg.png'
import kommunicon from '../../Assests/kommunicon.png'
import kommuncard from '../../Assests/kommunifi.png'
// import aboutpic from '../../Assests/aboutus-pic.png'
import kommunmob from '../../Assests/kommunmob.png'
import aboutusmain from '../../Assests/aboutus-main.png'
import careersimg from '../../Assests/careers-pic.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
// import CoverBg from '../../Assests/coverBg.png'
import testimonalbg from '../../Assests/testimonalbg.png'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { Carousel } from 'react-responsive-carousel';
import testimonalimg1 from '../../Assests/testimonal-img1.png'
import turbostartmob from '../../Assests/turbostartmob.png'
import mukeshjoshi from '../../Assests/caroseoul/MukeshJoshi.png'
// import { textAlign } from '@mui/system';
import SanjivaDubey from '../../Assests/caroseoul/dubey.png'
import sultan from '../../Assests/caroseoul/sultan2.png'

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '60px',
        paddingBottom: '60px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '12px',
            paddingBottom: '15px',

        },
        [theme.breakpoints.down('md')]: {
            paddingTop: '12px',
            paddingBottom: '15px',
        },

    },
    container2: {
        paddingTop: '100px ',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '36px',
        },
    },
    bg: {
        borderRadius: '15px',
        height: '485px',
        width: '100%',
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: 'center',
            backgroundSize: '400px',
            height: '250px'
        },
    },
    corevaluebg: {
        backgroundImage: `url(${corevalue})`,
        backgroundSize: 'cover',
        padding: '100px 90px 100px 140px',

        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${corevalue})`,
            padding: '50px 20px 80px 20px'
        },

        [theme.breakpoints.down('md')]: {
            backgroundImage: `url(${corevalue})`,
            padding: '50px 20px 80px 20px',
        },
        [theme.breakpoints.between('1400', '1450')]: {
            padding: '100px 55px 100px 120px',
        },
    },
    careersbg: {
        backgroundImage: `url(${carrersbg})`,
        backgroundSize: 'cover',
        padding: '150px 50px 250px 110px',

        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${carrersbg})`,
            backgroundSize: 'cover',
            width: '100vh',
            height: '100vh',
            padding: '20px 30px 150px 30px',
            backgroundPosition: 'center'
        },
        [theme.breakpoints.down('md')]: {
            backgroundImage: `url(${carrersbg})`,
            backgroundSize: 'cover',
            width: '100vh',
            height: '100vh',
            padding: '10px 30px 50px 30px',
            backgroundPosition: 'center'
        },
    },
    aboutus: {
        padding: '180px 125px 190px 125px',
        [theme.breakpoints.down('sm')]: {
            padding: '25px 25px 60px 25px'
        },
        [theme.breakpoints.down('md')]: {
            padding: '25px 25px 60px 25px'
        },
    },
    TestimonialSection: {
        backgroundImage: `url(${testimonalbg}})`,
        // backgroundSize: 'cover',
        // width: '100%',
        // height: '853px',
        width: '100vh',
        height: '100vh',
        padding: '50px 100px',
        backgroundPosition: 'center'
    },
    careersimg1: {
        paddingTop: '17px',
        [theme.breakpoints.between('376', '500')]: {
            paddingTop: '70px',
        },
    },
    careercont: {
        [theme.breakpoints.between('376', '500')]: {
            height: '150px',
        },
    },
    careerhead: {
        [theme.breakpoints.between('376', '500')]: {
            width: '250px',
            paddingTop: '20px'
        },
    },
    carrerbutton: {
        [theme.breakpoints.between('376', '500')]: {
            width: '150px',
        },
    },
    careermaincontent: {
        [theme.breakpoints.up('768')]: {
            height: '125px'
        },
    },
    // careermaincontent: {
    //     [theme.breakpoints.up('360')]: {
    //         height: '125px'
    //     },
    // },

    testicardheight: {
        [theme.breakpoints.between('360', '365')]: {
            height: '600px'
        },
        [theme.breakpoints.between('280', '282')]: {
            height: '750px'
        },
    },
    testicardheader: {
        [theme.breakpoints.between('360', '365')]: {
            paddingTop: '50px'
        },
        [theme.breakpoints.between('280', '282')]: {
            paddingTop: '200px'
        },
    },
    dubeymt: {
        [theme.breakpoints.between('280', '282')]: {
            paddingTop: '180px'
        },
    }

}))


function Second() {
    const themes = useTheme();
    const isMobileScreen = useMediaQuery(themes.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(themes.breakpoints.down('md'));
    const [currentSlide, setCurrentSlide] = useState(0)
    const classes = useStyles();
    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index);
        }
    };
    const next = () => {
        setCurrentSlide((prevSlide) => prevSlide + 1);
    };

    const prev = () => {
        setCurrentSlide((prevSlide) => prevSlide - 1);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        className: "custom-carousel",
    };
    // const settings1 = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     swipe: true,
    //     className: "custom-carousel1",

    // };

    const renderArrowPrev = () => null;
    const renderArrowNext = () => null;

    const [activeIndex, setActiveIndex] = useState(0);
    const playerRefs = useRef([]);

    const handleVideoPlay = (index) => {
        const playerIframe = playerRefs.current[index];
        const command = {
            event: 'command',
            func: 'playVideo',
        };

        // Pause all videos
        playerRefs.current.forEach((player, i) => {
            if (i !== index) {
                command.func = 'pauseVideo';
                player.contentWindow.postMessage(JSON.stringify(command), '*');
            }
        });

        // Play the clicked video
        command.func = 'playVideo';
        playerIframe.contentWindow.postMessage(JSON.stringify(command), '*');

        setActiveIndex(index);
    };


    return (
        <div >
            <div >
                {isMobileScreen ?
                    <>
                        <div className={`${classes.container} m-4`}  >
                            <div className="slider-container " style={{ width: "100%", height: "300px" }}>
                                {/* <Slider  {...settings1}> */}
                                    <div>
                                        <embed width="100%" height="300px" src="https://www.youtube.com/embed/_f0_x8HiS5k" className='rounded-4 custom-carousel1'></embed>
                                    </div>
                                    {/* <div>
                                        <embed width="100%" height="300px" src="https://www.youtube.com/embed/Botbr3BUwjU" className='rounded-4 custom-carousel1'></embed>
                                    </div> */}
                                {/* </Slider> */}
                            </div>
                            <Grid container spacing={6} className='mt-2' >
                                <Grid item xs={2}>
                                    <img src={KenIcon} alt='kenIcon' width={'50px'} height={'50px'} className='mt-1' />
                                </Grid>
                                <Grid item xs={10}>
                                    <div>The definitive operating system for education, Ken42 helps transform and streamline operations digitally from admissions to academics, to fees and finance.</div>
                                    <div className='mt-4'>
                                        <div className='primary-button font-primary-14-bold' onClick={() => window.open('https://www.ken42.com/', '_blank')}>Learn More</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {/* <div className={`${classes.container} m-4`}>
                            <Slider  {...settings1}>
                                <div>
                                    <embed width="100%" height="300px" src="https://www.youtube.com/embed/6Nch1uPqDUM" className='rounded-4 custom-carousel1'></embed>
                                </div>
                            </Slider>
                            <Grid container spacing={6} className='mt-2' >
                                <Grid item xs={2}>
                                    <img src={KennovateIcon} alt='kenIcon' width={'50px'} height={'50px'} className='mt-1' />
                                </Grid>
                                <Grid item xs={10}>
                                    <div>The comprehensive innovation and investment management platform, Kennovate empowers you to create custom flows that seamlessly align with your organization's evolving objectives.</div>
                                    <div className='mt-4'>
                                        <div className='primary-button font-primary-14-bold' onClick={() => window.open('https://www.ken42.com/', '_blank')}>Learn More</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div> */}
                        <div className={`${classes.container} m-4`}>
                            <Box className={classes.bg} sx={{ backgroundImage: `url(${kommuncard})` }}>
                            </Box>
                            <Grid container spacing={6} className='mt-2'>
                                <Grid item xs={2}>
                                    <img src={kommunmob} alt='kenIcon' width={'50px'} height={'50px'} className='mt-1' />
                                </Grid>
                                <Grid item xs={10}>
                                    <div>Kommunifi fosters togetherness through genuine interactions, engagement, events, gamification and feedback. Build, Engage and bring your community closer.</div>
                                    <div className='mt-4'>
                                        <div className='primary-button font-primary-14-bold' onClick={() => window.open('https://www.ken42.com/', '_blank')}>Learn More</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={`${classes.container} m-4`}>
                            <Box className={classes.bg} sx={{ backgroundImage: `url(${Video3})` }}>
                            </Box>
                            <Grid container spacing={6} className='mt-2'>
                                <Grid item xs={2}>
                                    <img src={KernelIcon} alt='kenIcon' width={'50px'} height={'50px'} className='mt-1' />
                                </Grid>
                                <Grid item xs={10}>
                                    <div>Kernel revolutionizes the educational landscape with a scalable platform that connects all school stakeholders and drives the transition from existing to extensive systems.</div>
                                    <div className='mt-4'>
                                        <div className='primary-button font-primary-14-bold' onClick={() => window.open('https://www.ken42.com/', '_blank')}>Learn More</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                    :
                    // Desktop view
                    <div className='w-100 p-4'>
                        {/* first card */}
                        <div className={classes.container} >
                            <div data-aos="fade-left" data-aos-duration="600">
                                <Grid container spacing={6}>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <div id="carouselExampleIndicators" className="carousel slide h-100" data-bs-ride="true">
                                            {/* <div class="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active caro-Color" aria-current="true" aria-label="Slide 1"></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" className='caro-Color'></button>
                                            </div> */}
                                            <div class=" h-100">
                                                <div class="h-100">
                                                    <iframe src={'https://www.youtube.com/embed/_f0_x8HiS5k'} className="d-block w-100 position-relative rounded-4" alt="..." height={'100%'}  onPlay={console.log('playing')}/>
                                                </div>
                                                {/* <div class="carousel-item h-100">
                                                    <iframe src={'https://www.youtube.com/embed/204WyN7lDt4'} className="d-block w-100 position-relative rounded-4" alt="..." height={'100%'} />
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <div id="carouselExampleIndicators" className="carousel slide h-100" data-bs-ride="true">
                                            <div className="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className={`active caro-Color ${activeIndex === 0 ? 'playing' : ''}`} aria-current="true" aria-label="Slide 1" onClick={() => handleVideoPlay(0)}></button>
                                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className={`caro-Color ${activeIndex === 1 ? 'playing' : ''}`} aria-label="Slide 2" onClick={() => handleVideoPlay(1)}></button>
                                            </div>
                                            <div className="carousel-inner h-100">
                                                <div className={`carousel-item h-100 ${activeIndex === 0 ? 'active' : ''}`}>
                                                    <iframe
                                                        title="Video 1"
                                                        src="https://www.youtube.com/embed/Botbr3BUwjU"
                                                        className="d-block w-100 position-relative rounded-4"
                                                        height="100%"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                        ref={(ref) => (playerRefs.current[0] = ref)}
                                                    />
                                                </div>
                                                <div className={`carousel-item h-100 ${activeIndex === 1 ? 'active' : ''}`}>
                                                    <embed
                                                        title="Video 2"
                                                        src="https://www.youtube.com/embed/204WyN7lDt4"
                                                        className="d-block w-100 position-relative rounded-4"
                                                        height="100%"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                        ref={(ref) => (playerRefs.current[1] = ref)}

                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                    </Grid>
                                    <Grid item md={6} xs={12} sm={6} >
                                        <div className={isMobileScreen ? 'p-0' : 'p-4'}>
                                            <img src={KenIcon} alt='kenIcon' />
                                            <div className='font-black-20-normal mt-4'>The definitive operating system for education, Ken42 helps transform and streamline operations digitally from admissions to academics, to fees and finance.</div>

                                            <br />
                                            <br />
                                            <button className='primary-button font-primary-22-bold' style={{ width: '180px' }} onClick={() => window.open('https://www.ken42.com/', '_blank')}>Learn More</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        {/* second card */}
                        {/* <div className={`${classes.container}`} >
                            <div data-aos="fade-right" data-aos-duration="600" >
                                <Grid container >
                                    <Grid item md={6} sm={6} xs={12} >
                                        <div className={isMobileScreen ? 'p-0' : 'p-4'} >
                                            <img src={KennovateIcon} alt='KenIcon' />
                                            <div className='font-black-20-normal mt-4'>The comprehensive innovation and investment management platform, Kennovate empowers you to create custom flows that seamlessly align with your organization's evolving objectives.</div>

                                            <br /> <br />
                                            <button className='primary-button font-primary-22-bold' style={{ width: '180px' }}>Learn More</button>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12} sm={6}>
                                        <div id="carouselExampleIndicators2" className="carousel slide h-100" data-bs-ride="true">
                                            <div class="carousel-inner h-100">
                                                <div class="carousel-item h-100 active">
                                                    <iframe src={'https://www.youtube.com/embed/6Nch1uPqDUM'} className="d-block w-100 rounded-4 position-relative" alt="..." height={'100%'} />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div> */}
                        {/* third card */}
                        <div className={classes.container}>
                            <Grid container data-aos="fade-left" data-aos-duration="600">
                                <Grid item md={6} xs={12} sm={6} >
                                    <iframe className={classes.bg}
                                        frameborder="0"
                                        scrolling="no"
                                        src={kommuncard}>
                                    </iframe>
                                </Grid>
                                <Grid item md={6} xs={12} sm={6} >
                                    <div className={isMobileScreen ? 'p-0' : 'p-4'} style={{ marginLeft: '50px' }}>
                                        <img src={kommunicon} alt='kommunicon' />
                                        <div className='font-black-20-normal mt-4'>Kommunifi fosters togetherness through genuine interactions, engagement, events, gamification and feedback. Build, Engage and bring your community closer.</div>

                                        <br /> <br />
                                        <button className='primary-button font-primary-22-bold' style={{ width: '180px' }}>Learn More</button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {/* fourth card */}

                        <div className={classes.container}>
                            <Grid container data-aos="fade-right" data-aos-duration="600">
                                <Grid item md={6} xs={12} sm={6} >
                                    <div className={isMobileScreen ? 'p-0' : 'p-4'} style={{ marginLeft: '50px' }}>
                                        <img src={KernelIcon} alt='kernelicon' />
                                        <div className='font-black-20-normal mt-4'>Kernel revolutionizes the educational landscape with a scalable platform that connects all school stakeholders and drives the transition from existing to extensive systems.</div>

                                        <br /> <br />
                                        <button className='primary-button font-primary-22-bold' style={{ width: '180px' }}>Learn More</button>
                                    </div>
                                </Grid>
                                <Grid item md={6} xs={12} sm={6} >
                                    <iframe className={classes.bg}
                                        frameborder="0"
                                        scrolling="no"
                                        src={Video3}>
                                    </iframe>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
                <div className={isMobileScreen ? 'm-2 pb-5' : 'w-100'}>
                    <div>
                        <Box className={isMobileScreen ? 'pt-4' : 'p-5'}>
                            {isMobileScreen ?
                                <div className='p-3'>
                                    <div className='font-black-16-normal'>
                                        <div style={{ textAlign: 'center' }}><span className='fw-bold'>At Kenverse,</span> our mission is to facilitate organizational transformation,
                                            <span className='fw-bold'> driving business impact, scale and sustainability {' '}</span>
                                            through a suite of cutting-edge platforms. Fueled by technology and design thinking, we are on a path to transcend limitations and
                                            <span className='fw-bold'> push the boundaries of what is possible.</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className='font-black-40-normal pt-5 d-flex justify-content-center p-4'>
                                        <Box component={'div'} width={'100%'} >
                                            <div style={{ textAlign: 'justify' }} ><img src={quotation1} alt='quotation1' className='pb-4' /><span className='fw-bold'>At Kenverse,</span> our mission is to facilitate organizational transformation,
                                                <span className='fw-bold'> driving business impact, scale and sustainability {' '}</span>
                                                through a suite of cutting-edge platforms. Fueled by technology and design thinking, we are on a path to transcend limitations and
                                                <span className='fw-bold'> push the boundaries of what <span style={{ textIndent: '30px' }}> is possible.</span><img src={quotation2} alt='quotation2' className='pb-4' /></span>
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                            }
                        </Box>
                    </div>

                    {/* Testimonial */}
                    {isMobileScreen ?
                        <>
                            {/* 1st card */}
                            <div className='pt-3'>
                                <div className='Testimonial-sectionmob'>
                                    <Slider {...settings}>
                                        {/* 2nd card */}
                                        <div className={`${classes.testicardheight} testimonalcard-mobile`}>
                                            <img src={mukeshjoshi} style={{ width: '100%', height: '171px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} />
                                            <div className='p-4 pt-4'>
                                                <div className='font-black-14-normal pb-2' style={{ height: '200px', textAlign: 'justify' }}>At FLAME university we are striving to build a world class, technology driven education ecosystem and Kenverse has been a trusted platform partner over the last few years helping us keep challenging the norm and build the future of how we educate together. Their team has a solution oriented approach always and are always up for a challenge.</div>
                                                <div className={` ${classes.testicardheader} font-black-16-bold mt-4`}>Mukesh Joshi</div>
                                                <div className='font-black-12-normal'>Director Technology - Information Systems, CTO (Integrator) at FLAME University</div>
                                            </div>
                                        </div>
                                        {/* 3rd card */}
                                        <div className={`${classes.testicardheight} testimonalcard-mobile`}>
                                            <img src={SanjivaDubey} style={{ width: '100%', height: '171px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} />
                                            <div className='p-4 pt-4'>
                                                <div className='font-black-14-normal pb-2' style={{ height: '200px', textAlign: 'justify' }}>Over the last 4 years, we have been using the LMS built by the team at Kenverse and it has been a wonderful experience working with the team, they are very responsive and any and all support required is delivered in a timely manner.</div>
                                                <div className={`${classes.dubeymt} font-black-16-bold mt-5`}>Sanjiva Shankar Dubey</div>
                                                <div className='font-black-12-normal'>Chairperson Centre for Online Studies - COOLs</div>
                                            </div>
                                        </div>
                                        {/* 4th card */}
                                        <div className={`${classes.testicardheight} testimonalcard-mobile`}>
                                            <img src={sultan} style={{ width: '100%', height: '171px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} />
                                            <div className='p-4 pt-4'>
                                                <div className='font-black-14-normal pb-2' style={{ height: '200px', textAlign: 'justify' }}>At LxL Ideas we have revolutionized the education sector with our innovative, research-led film-based pedagogy and we are really excited to chart the next step in our growth with Kenverse’s platforms that are the driving force behind helping us get greater reach and thus make a bigger impact. It has been great working with the team so far.</div>
                                                <div className={` ${classes.testicardheader} font-black-16-bold mt-4`}>Syed Sultan Ahmed</div>
                                                <div className='font-black-12-normal'>Founder, MD and Chief Learner, LxL</div>
                                            </div>
                                        </div>
                                        {/* 1st card */}
                                        <div className={`${classes.testicardheight} testimonalcard-mobile`}>
                                            <img src={testimonalimg1} style={{ width: '100%' }} />
                                            <div className='p-4 pt-4'>
                                                <div className='font-black-14-normal pb-2' style={{ textAlign: 'justify' }}>At Turbostart, managing our growing operations right from curating the right startups to engaging with the ecosystem was becoming increasingly cumbersome, that’s when we turned to Kennovate, the innovation management product of Kenverse. The platform has helped us streamline, build better visibility and keep our stakeholders engaged.</div>
                                                <div className='font-black-16-bold mt-4'>Venkat Raju</div>
                                                <div className='font-black-12-normal'>Global CEO</div>
                                                <div className='pt-3'><img src={turbostartmob} /></div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>

                        </>
                        :
                        <div >
                            <div className='Testimonial-section'>
                                <div className='Testimonial-card position-relative'>
                                    <Carousel autoPlay={true} selectedItem={currentSlide} onChange={updateCurrentSlide} showThumbs={false} showStatus={false} interval={5000} infiniteLoop renderArrowPrev={renderArrowPrev} renderArrowNext={renderArrowNext}>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} height={"400px"} width={'100%'} className='rounded-3' sx={{ backgroundPosition: 'center' }}>
                                                        <img src={require('../../Assests/caroseoul/MukeshJoshi.png')} class="caro-MainImg w-100" alt="..." height={'100%'} width={'100%'} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} padding={'20px'}>
                                                        <Box component={'h2'} textAlign={'justify'} className='font-black-18-normal lh-base'>At FLAME university we are striving to build a world class, technology driven education ecosystem and Kenverse has been a trusted platform partner over the last few years helping us keep challenging the norm and build the future of how we educate together. Their team has a solution oriented approach always and are always up for a challenge.</Box>
                                                        <h1 className='font-black-20-bold mt-4  text-start'>Mukesh Joshi</h1>
                                                        <h1 className='font-black-18-normal mt-1  text-start'>Director Technology - Information Systems, CTO (Integrator) at FLAME University</h1>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} height={"400px"} width={'100%'} className='rounded-3'>
                                                        <img src={require('../../Assests/caroseoul/sultan2.png')} class="caro-MainImg w-100 h-100" alt="..." height={'100%'} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} padding={'20px'}>
                                                        <div className='content-area'>
                                                            <Box component={'h2'} textAlign={'justify'} className='font-black-18-normal lh-base'>At LxL Ideas we have revolutionized the education sector with our innovative, research-led film-based pedagogy and we are really excited to chart the next step in our growth with Kenverse’s platforms that are the driving force behind helping us get greater reach and thus make a bigger impact. It has been great working with the team so far.</Box>
                                                        </div>
                                                        <h1 className='font-black-20-bold mt-4  text-start'><b>Syed Sultan Ahmed</b></h1>
                                                        <h1 className='font-black-18-normal mt-1  text-start'>Founder, MD and Chief Learner, LxL</h1>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6} justifyContent={'center'}>
                                                    <Box component={'div'} height={"400px"} width={'100%'} className='rounded-3 d-flex justify-content-center'>
                                                        <img src={require('../../Assests/caroseoul/dubey.png')} class="w-100 h-100 caro-MainImg" alt="..." height={'100%'} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} padding={'20px'}>
                                                        <Box component={'h2'} textAlign={'justify'} className='font-black-18-normal lh-base'>Over the last 4 years, we have been using the LMS built by the team at Kenverse and it has been a wonderful experience working with the team, they are very responsive and any and all support required is delivered in a timely manner.</Box>
                                                        <h1 className='font-black-20-bold mt-4 text-start'><b>Sanjiva Shankar Dubey</b></h1>
                                                        <h1 className='font-black-18-normal mt-1 text-start'>Chairperson Centre for Online Studies - COOLs</h1>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} height={"400px"} width={'100%'} className='rounded-3' sx={{ backgroundPosition: 'center' }}>
                                                        <img src={require('../../Assests/caroseoul/venkat.png')} class=" w-100  caro-MainImg" alt="..." height={'100%'} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={6}>
                                                    <Box component={'div'} padding={'20px'}>
                                                        <Box component={'h2'} textAlign={'justify'} className='font-black-18-normal lh-base '>At Turbostart, managing our growing operations right from curating the right startups to engaging with the ecosystem was becoming increasingly cumbersome, that’s when we turned to Kennovate, the innovation management product of Kenverse. The platform has helped us streamline, build better visibility and keep our stakeholders engaged.</Box>
                                                        <h1 className='font-black-20-bold mt-3 text-start'><b>Venkat Raju</b></h1>
                                                        <h1 className='font-black-18-normal mt-1 text-start'>Global CEO, Turbostart</h1>
                                                        <img src={require('../../Assests/tubologo.png')} alt='logo' height={'66px'} width={'20px'} className='caro-img mt-3' />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Carousel>
                                    <Box component={'div'} className='position-absolute d-flex gap-3 align-items-center caro-button-container'>
                                        <button className='caro-button' onClick={() => prev()}><MdOutlineKeyboardArrowLeft fontSize={'20px'} style={{ margin: '-41px 30px -32px -7px ' }} /></button>
                                        <Box component={'h2'} className='font-black-14-bold mt-1'>{currentSlide === undefined ? '1' : currentSlide + 1}/4</Box>
                                        <button className='caro-button' onClick={() => next()}><MdOutlineKeyboardArrowRight fontSize={'20px'} style={{ margin: '-42px 30px -33px -4px ' }} /></button>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <>
                        {/* core values */}
                        <div>
                            <Grid container className={`${classes.corevaluebg}`}>
                                <Box>
                                    <div className={classes.container}>
                                        <h6 className={isMobileScreen ? 'font-red-40-bold' : 'Title3'}>The Ken</h6>
                                        <h6 className={isMobileScreen ? 'font-silver-52-bold' : 'Title4 pb-2'}>Philosophy</h6>
                                    </div>
                                </Box>
                                <Grid container spacing={isMobileScreen ? 2 : 5} paddingTop={3}>
                                    <Grid item lg={4.5} xs={12}>
                                        <div className={isMobileScreen ? 'font-white-45-thin' : 'font-white-60-thin'}>Ownership</div>
                                    </Grid>
                                    <Grid item lg={7.5} xs={12}>
                                        {isMobileScreen ?
                                            <div className='font-white-24-normal pb-4'>Our culture fosters an innate sense of
                                                <span className='font-white-24-bold px-2'>ownership</span>{' '}to drive impact.</div>
                                            :
                                            <div className='font-white-40-normal pb-4'>Our culture fosters an innate sense of{' '}
                                                <span className='font-white-40-bold'>ownership</span>{' '}to drive impact.</div>
                                        }
                                        <Box className={isMobileScreen ? 'font-white-18-normal' : 'font-white-20-normal'} width={isMobileScreen ? '330px' : '550px'}> We are devoted to not just getting things done, but to get it done right, ensuring that it aligns with the highest standards.</Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={isMobileScreen ? 2 : 5} paddingTop={isMobileScreen ? 8 : 20}>
                                    <Grid item lg={4.5} xs={12}>
                                        <div className={isMobileScreen ? 'font-white-45-thin' : 'font-white-60-thin '}>Collaboration</div>
                                    </Grid>
                                    <Grid item lg={7.5} xs={12}>
                                        {isMobileScreen ?
                                            <div className='font-white-24-normal pb-4'>The transformative force of
                                                <span className='font-white-24-bold px-2'> collaboration</span> knows no bounds.</div>
                                            :
                                            <div className='font-white-40-normal pb-4'>The transformative force of<br />
                                                <span className='font-white-40-bold px-2'>collaboration</span>knows no bounds.</div>
                                        }
                                        <Box className={isMobileScreen ? 'font-white-18-normal' : 'font-white-20-normal'} width={isMobileScreen ? '330px' : '550px'}>We thrive on the power of synergy, our collective purpose drives us to think and act as one.</Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={isMobileScreen ? 2 : 5} paddingTop={isMobileScreen ? 8 : 20}>
                                    <Grid item lg={4.5} xs={12}>
                                        <div className={isMobileScreen ? 'font-white-45-thin' : 'font-white-60-thin '}>Growth</div>
                                    </Grid>
                                    <Grid item lg={7.5} xs={12}>
                                        {isMobileScreen ?
                                            <div className='font-white-24-normal pb-4'>In the world of Ken,
                                                <span className='font-white-24-bold px-2'>Growth and Change </span>is a constant.</div>
                                            :
                                            <div className='font-white-40-normal pb-4'>In the world of Ken,
                                                <span className='font-white-40-bold px-2'>Growth and <br /> Change </span>is a constant.</div>
                                        }
                                        <Box className={isMobileScreen ? 'font-white-18-normal' : 'font-white-20-normal'} width={isMobileScreen ? '330px' : '550px'}> We strive to push the boundaries of our understanding and are determined to keep pace with the ever-evolving world.</Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={isMobileScreen ? 2 : 5} paddingTop={isMobileScreen ? 8 : 20}>
                                    <Grid item lg={4.5} xs={12}>
                                        <div className={isMobileScreen ? 'font-white-45-thin' : 'font-white-60-thin'}>Empathy</div>
                                    </Grid>
                                    <Grid item lg={7.5} xs={12}>
                                        {isMobileScreen ?
                                            <>
                                                <span className='font-white-24-bold '>Empathy</span>{' '}
                                                <span className='font-white-24-normal'>defines our collective spirit.</span>
                                            </>
                                            :
                                            <>
                                                <span className='font-white-40-bold'>Empathy</span>{' '}
                                                <span className='font-white-40-normal'> defines our collective spirit.<br /></span>
                                            </>
                                        }
                                        <Box className={isMobileScreen ? 'font-white-18-normal pt-4' : 'font-white-20-normal pt-4'} width={isMobileScreen ? '330px' : '550px'}> Empathy forms the crux of every endeavor at Ken,nurturing relationships, wisdom and personal growth.</Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={isMobileScreen ? 2 : 5} paddingTop={isMobileScreen ? 8 : 20}>
                                    <Grid item lg={4.5} xs={12}>
                                        <div className={isMobileScreen ? 'font-white-45-thin' : 'font-white-60-thin'}>Zest</div>
                                    </Grid>
                                    <Grid item lg={7.5} xs={12}>
                                        {isMobileScreen ?
                                            <div className='font-white-24-normal'>Having a
                                                <span className='font-white-24-bold px-2'>blast at work </span>is a core belief.</div>
                                            :
                                            <div className='font-white-40-normal'>Having a
                                                <span className='font-white-40-bold px-2'>blast at work Change <br /> </span>is a core belief.</div>
                                        }
                                        <Box className={isMobileScreen ? 'font-white-18-normal pt-4' : 'font-white-20-normal pt-4'} width={isMobileScreen ? '330px' : '550px'}>We revel in energy- passion and enthusiasm is vital to our overall well-being and ethos.</Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                        {/* about us */}
                        <div>
                            <Grid container spacing={isMobileScreen ? 2 : 5} className={classes.aboutus}>
                                <Grid item lg={7} xs={12} id="aboutus">
                                    <Box className={isMobileScreen ? 'fw-bold pb-4 pt-5 ' : 'fw-bold pb-5'} fontSize={isMobileScreen ? '52px' : '120px'}>About Us</Box>
                                    <Box className={isMobileScreen ? 'font-black-16-normal pb-4' : 'font-black-30-normal pb-5'} width={isMobileScreen ? '100%' : '550px'}>A suite of{' '}<span className={isMobileScreen ? 'font-black-16-bold' : 'font-black-30-bold'}>SaaS based solutions,</span>with an automation first approach, Kenverse provides organizations with the best of technology in today’s world, all through a<span className={isMobileScreen ? 'font-black-16-bold' : 'font-black-30-bold'}> single unified platform.</span>We are part of Turbostart’s Enterprise SaaS network of startups.</Box>

                                    <Box className={isMobileScreen ? 'font-black-16-normal pb-2' : 'font-black-30-normal'} width={isMobileScreen ? '100%' : '600px'}>Our vision is to build ground-breaking solutions to aid a transformative experience where  <span className={isMobileScreen ? 'font-black-16-bold' : 'font-black-30-bold'}>cutting-edge solutions meet organizational excellence.</span></Box>
                                </Grid>
                                <Grid item lg={5} xs={12}>

                                    <Box paddingTop={isMobileScreen ? 0 : 5} >
                                        {isMobileScreen ?
                                            <>
                                                <img src={aboutusmain} alt='aboutpic' style={{ width: '100%', height: 'auto' }} />
                                            </> : <img src={aboutusmain} alt='aboutus' style={{ width: '470px' }} className='pt-4' />}
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        {/* careers*/}
                        <div>
                            <Grid container>
                                {isMobileScreen ?
                                    <>
                                        <Grid item lg={12} xs={12} className={classes.careersbg}>
                                            <Box className='careersmob pb-2' id="careers">Careers</Box>
                                            <div className={`font-white-20-normal pb-4 ${classes.careerhead}`} >Are you an inquisitive and persistent individual?</div>
                                            <div className={`font-white-16-normal pb-4 ${classes.careercont}`}>We value high-spirited individuals driven by passion over years
                                                of experience. If you possess a winning mindset and a knack for problem-solving, send us your CV.</div>
                                            <div><button className={`primary-button font-black-12-normal p-2 ${classes.carrerbutton}`} onClick={() => window.open('https://www.linkedin.com/company/kenverse/', '_blank')}>Learn More</button> </div>
                                            <div className={classes.careersimg1}>
                                                <img src={careersimg} style={{ maxWidth: '100%', height: 'auto' }} />
                                            </div>
                                        </Grid>
                                    </> :
                                    <>
                                        <Grid item lg={12} xs={12} md={12} className={classes.careersbg}>
                                            <Grid container>
                                                <Grid item lg={6} xs={12} md={12}>
                                                    <h5 className='careers pb-4' id="careers">Careers<img src={diamond} className='pt-4 px-4' style={{ width: '80px' }} /></h5>
                                                    <div className='font-white-40-normal pb-5' >Are you an inquisitive and <br /> persistent individual?</div>
                                                    <div className={`font-white-20-normal pb-5 ${classes.careermaincontent}`} style={{ width: '87%' }}>We value high-spirited individuals driven by passion over years
                                                        of experience. If you possess a winning mindset and a knack for  problem-solving, send us your CV.</div>
                                                    <div className={`pt-3 ${classes.careerbutton}`}><button className='primary-button' onClick={() => window.open('https://www.linkedin.com/company/kenverse/', '_blank')} style={{ width: '160px' }}>Learn More</button> </div>
                                                </Grid>
                                                <Grid item lg={6} xs={12} md={12}>
                                                    <img src={careersimg} style={{ maxWidth: '100%', height: 'auto' }} className='pt-5'/>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>
                                }
                            </Grid>
                            <span id='contactus'></span>
                        </div>
                    </>
                </div>
            </div >
        </div >

    )
}

export default Second;
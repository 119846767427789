import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Website from './Website';
// import Header from './component/Header/index';
import { Routes, Route } from 'react-router-dom';

function App() {
  const fontFamily = 'Inter';
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: [fontFamily, 'Quicksand'].join(','),
        textTransform: 'none',
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route excat path={'/'} element={<Website />} />
          <Route excat path={'/home'} element={<Website />} />
        </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App